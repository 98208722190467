<template>
  <header class=" text-center">
    <nav class="navbar navbar-expand-lg fixed-top">
      <div class="container">
        <div class="nav-flex">
          <div class="brand-name">
            <router-link to="/">
              <img src="../assets/adere_logo.png" />
            </router-link>
          </div>

          <div class="nav-list-two">
            <div class="nav-list-onewe">
              <div class="menu">
                <div @click="openNav()">
                  <div>
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                  </div>
                </div>
              </div>
              <router-link to="/accommodation">
                <span
                  class="mr-4 list-one"
                  :class="{ active: $route.name == 'Accommodation' }"
                  style="cursor: pointer"
                >Accommodation</span>
              </router-link>
              <router-link to="/dining">
                <span
                  class="mr-4 list-one"
                  :class="{ active: $route.name == 'Dining' }"
                  style="cursor: pointer"
                >Dining</span>
              </router-link>
              <router-link to="/experiences">
                <span
                  class="mr-4 list-one"
                  :class="{ active: $route.name == 'Experiences' }"
                  style="cursor: pointer"
                >Experiences</span>
              </router-link>
            </div>

            <div
              style="width: 200px; height: 100%; cursor: pointer"
              @click="$router.push({ path: '/' })"
            ></div>

            <div>
              <router-link to="/about-us">
                <span
                  class="mr-4 list-one"
                  :class="{ active: $route.name == 'AboutUs' }"
                  style="cursor: pointer"
                >About Us</span>
              </router-link>
              <router-link to="/gallery">
                <span
                  class="mr-4 list-one"
                  :class="{ active: $route.name == 'Gallery' }"
                  style="cursor: pointer"
                >Gallery</span>
              </router-link>
              <router-link to="/contact-us">
                <span :class="{ active: $route.name == 'ContactUs' }">Contact Us</span>
              </router-link>

              <div
                class="showMobileOnly"
                @click="$router.push({path: '/accommodation'})"
              >
                <i
                  class="far fa-calendar-alt"
                  style="font-size: 1.5em"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <el-drawer
      title="I am the title"
      :visible.sync="sidebarDrawer"
      direction="ltr"
      size="70%"
      :with-header="false"
    >
      <div class="sidebar">
        <ul>
          <li @click="closeNav()">
            <router-link to="/">
              <span
                class="mr-4 list-one"
                style="cursor: pointer"
              >Home</span>
            </router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/accommodation">
              <span
                class="mr-4 list-one"
                style="cursor: pointer"
              >Accommodation</span>
            </router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/dining">
              <span
                class="mr-4 list-one"
                style="cursor: pointer"
              >Dining</span>
            </router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/experiences">
              <span
                class="list-one"
                style="cursor: pointer"
              >Experiences</span>
            </router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/about-us">
              <span
                class="mr-4 list-one"
                style="cursor: pointer"
              >About Us</span>
            </router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/gallery">
              <span class="mr-4">Gallery</span>
            </router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/contact-us">
              <span class="mr-4">Contact Us</span>
            </router-link>
          </li>
          <!-- <li @click="closeNav()">
            <router-link to="/our-people">
              <span class="mr-4">Our People</span>
            </router-link>
          </li> -->
        </ul>
      </div>
    </el-drawer>
  </header>
</template>

<script>
// import SidebarComponent from './sidebar-component.vue';
export default {
  // components: {SidebarComponent},
  data() {
    return {
      drawer: false,
      sidebarDrawer: false,
    };
  },

  methods: {
    openNav() {
      this.sidebarDrawer = true;
    },

    closeNav() {
      this.sidebarDrawer = false;
    },
  },
};
</script>

<style scoped>
nav {
  background-color: #ffffff;
  color: #3d290a;
}
nav a {
  color: #3d290a;
  text-decoration: none;
}
a .active {
  border-bottom: 1px solid #d96b12;
  color: #d9ae3b;
  font-weight: 600;
}

a:hover span {
  border-bottom: 1px solid #d96b12;
  transition: 0.5s;
}

.side-link {
  color: var(--el-app-primary);
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex {
  width: 100%;
  height: 50px;
  position: relative;
}
.nav-flex > div:first-child,
.nav-flex > div:last-child {
  width: 100%;
}

.nav-flex .nav-list-one {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;

  position: absolute;
  left: 0px;
}

.list-one {
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex .nav-list-two {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  right: 0px;
}

.nav-flex span {
  font-size: 1em;
  font-weight: 400;
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex span:hover {
  color: #d96b12;
  transition: 0.5s;
}

.brand-name {
  position: absolute;
  top: 0px;
  margin: 0px auto;
}

.brand-name img {
  width: 150px;
  padding: 0px;
  margin: 0px;
}

.bookBtn {
  border: 1px solid #fbf2e1;
  border-radius: 0px;
  background-color: transparent;
  color: #fbf2e1;
  transition: 0.5s;
}

.bookBtn:hover {
  color: #d96b12;
  transition: 0.5s;
}

.menu {
  display: none;
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #513f03;
  margin: 5px 0;
  border-radius: 3px;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.showDesktopOnly {
  display: block;
}
.showMobileOnly {
  display: none;
}

.sidebar {
  background-color: #3d290a;
  height: 100%;
  padding: 50px 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  text-align: left;
}

.sidebar ul li {
  border-bottom: 1px solid #684613;
  padding-top: 30px;
}

.sidebar ul li a {
  color: #fbf0e0;
}

.sidebar ul li a:hover {
  color: #fdfdfd;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .menu {
    display: block;
  }

  .nav-flex {
    height: 30px;
  }

  .nav-flex .nav-list-one span {
    display: none;
  }

  .nav-flex .nav-list-two span {
    display: none;
  }

  .brand-name {
    position: absolute;
    top: -7px;
    margin: 0px auto;
  }

  .brand-name img {
    width: 250px;
  }

  .showDesktopOnly {
    display: none;
  }
  .showMobileOnly {
    display: block;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .menu {
    display: block;
  }

  .nav-flex {
    height: 30px;
  }

  .nav-flex .nav-list-one span {
    display: none;
  }

  .nav-flex .nav-list-two span {
    display: none;
  }

  .brand-name {
    position: absolute;
    top: -7px;
    margin: 0px auto;
  }

  .brand-name img {
    width: 250px;
  }

  .showDesktopOnly {
    display: none;
  }
  .showMobileOnly {
    display: block;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .menu {
    display: block;
  }

  .nav-flex {
    height: 30px;
  }

  .nav-flex .nav-list-one span {
    display: none;
  }

  .nav-flex .nav-list-two span {
    display: none;
  }

  .brand-name {
    position: absolute;
    top: -7px;
    margin: 0px auto;
  }

  .brand-name img {
    width: 250px;
  }

  .showDesktopOnly {
    display: none;
  }
  .showMobileOnly {
    display: block;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .menu {
    display: block;
  }

  .nav-flex {
    height: 30px;
  }

  .nav-flex .nav-list-one span {
    display: none;
  }

  .nav-flex .nav-list-two span {
    display: none;
  }

  .brand-name {
    position: absolute;
    top: -7px;
    margin: 0px auto;
  }

  .brand-name img {
    width: 120px;
  }

  .showDesktopOnly {
    display: none;
  }
  .showMobileOnly {
    display: block;
  }
}
</style>

<style>
el-drawer {
  background-color: red;
}
</style>