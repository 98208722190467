<template>
  <div id="app">
    <!-- <transition name="router-anim"> -->
    <router-view />
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  // then, in the parent component,
  // watch the `$route` to determine the transition to use
  // watch: {
  //   $route(to, from) {
  //     const toDepth = to.path.split("/").length;
  //     const fromDepth = from.path.split("/").length;
  //     this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
  //   },
  // },
};
</script>

<style>
#app {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3d290a;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3d290a;
}
</style>
